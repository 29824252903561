<template>
  <div class="app-container">
    <div class="outer">
      <div v-if="!userInfo.isMemberStore" style="margin-bottom: 10px;">
        <el-alert title="对账单生成后请及时确认，超过90天未确认，系统将默认对账金额无误，进行自动确认。" type="info" :closable="false" show-icon></el-alert>
      </div>
      <el-tabs
        v-model="activeName"
        type="border-card"
      >
        <el-tab-pane
          label="直发"
          name="1"
        >
          <OrderList v-if="activeName === '1'" />
        </el-tab-pane>

        <!-- <el-tab-pane
          label="到仓"
          name="2"
        >
          <import-order v-if="activeName === '2'" />
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import OrderList from './orderList.vue';

export default {
  name: '',
  components: {
    OrderList,
  },
  props: {},
  data() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return {
      activeName: '1',
    };
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
</style>
