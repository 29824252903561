var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        !_vm.userInfo.isMemberStore
          ? _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c("el-alert", {
                  attrs: {
                    title:
                      "对账单生成后请及时确认，超过90天未确认，系统将默认对账金额无误，进行自动确认。",
                    type: "info",
                    closable: false,
                    "show-icon": "",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-tabs",
          {
            attrs: { type: "border-card" },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "直发", name: "1" } },
              [_vm.activeName === "1" ? _c("OrderList") : _vm._e()],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }