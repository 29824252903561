<template>
  <div>
    <SearchPanel
      flex
      show-btn
      size="mini"
      @getList="onSearch"
    >
      <div v-if="isMemberStore">
        <p>
          供应商ID:
        </p>
        <el-input
          v-model.trim="searchData.supplierID"
          placeholder="请输入供应商ID"
          size="mini"
          type="number"
          clearable
        />
      </div>
      <div v-if="isMemberStore">
        <p>
          供应商名称:
        </p>
        <el-input
          v-model.trim="searchData.supplierName"
          placeholder="请输入供应商名称"
          size="mini"
          clearable
        />
      </div>
      <div>
        <p>
          对账单名称:
        </p>
        <el-input
          v-model.trim="searchData.orderNo"
          placeholder="请输入对账单名称"
          size="mini"
          clearable
        />
      </div>
      <div>
        <p>
          状态
        </p>
        <el-select
          v-model="searchData.type"
          size="mini"
          placeholder="请选择状态"
          filterable
          clearable
          @change="getSearchList()"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value + 'type'"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div>
        <p>
          创建时间
        </p>
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          size="mini"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        />
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="resetSearch"
      >
        重 置
      </el-button>
      <el-button
        slot="button-right"
        type="primary"
        size="mini"
        :loading="downLoading"
        @click="download"
      >
        导 出
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="loading"
      :data="tableData"
      class="tableBox"
      :cell-style="{
        background: '#FAFAFA'
      }"
    >
      <el-table-column
        label="对账单名称"
        min-width="230"
      >
        <template slot-scope="{row}">
          <span>{{ row.settlementInterval }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isMemberStore"
        label="供应商ID"
        min-width="180"
      >
        <template slot-scope="{row}">
          <span>{{ row.supplierId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isMemberStore"
        label="供应商"
      >
        <template slot-scope="{row}">
          <span>{{ row.supplierName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="订货金额（元）"
        width="120"
      >
        <template slot-scope="{row}">
          <span>{{ row.purchaseAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="订货退货金额（元）"
        width="160"
      >
        <template slot-scope="{row}">
          <span>{{ row.refundAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="实际订货金额（元）"
        width="160"
      >
        <template slot-scope="{row}">
          <span>{{ row.actuallyAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="订货对账金额（元）"
        width="160"
      >
        <template slot-scope="{row}">
          <span>{{ row.checkAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="{row}">
          <span>{{ row.auditStatusName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        min-width="160"
      >
        <template slot-scope="{row}">
          <span>{{ formatTime(row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="280"
        fixed="right"
      >
        <template slot-scope="{row}">
          <el-button
            size="mini"
            type="primary"
            @click="handleDetail(row,'detail')"
          >
            查看
          </el-button>
          <el-button
            v-if="isMemberStore&& (row.auditStatus === 0
              || row.auditStatus === -1)"
            size="mini"
            type="primary"
            @click="handleDetail(row,'edit')"
          >
            编辑
          </el-button>
          <el-button
            v-if="isMemberStore&& row.auditStatus === 0"
            size="mini"
            type="primary"
            @click="submit(row)"
          >
            提交
          </el-button>
          <el-button
            v-if="row.auditStatus === 1 && !isMemberStore"
            size="mini"
            type="primary"
            @click="handleDetail(row,'edit')"
          >
            审核
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="exportData(row)"
          >
            导出
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog
      title="订货退货审核"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form :model="form">
        <el-form-item label="审核结果">
          <el-radio-group v-model="form.state">
            <el-radio :label="1">
              同意
            </el-radio>
            <el-radio :label="-1">
              拒绝
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="原因说明">
          <el-input
            v-model="form.reason"
            type="textarea"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="examineClose">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="examineConfirm"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref, getCurrentInstance, onMounted,
} from 'vue';
import { set } from 'lodash';
import dayjs from 'dayjs';
import { downloadFile } from '@/utils/download';

const app = getCurrentInstance().proxy;
const loading = ref(false);
const searchData = ref({
  beginTime: '', // 查询-下单时间
  endTime: '', // 查询-下单时间
  orderNo: '', // 查询-对账单名称
  type: '', // 查询-对账单类型
  supplierID: '', // 查询-供应商
  supplierName: '', // 查询-供应商
});
const form = ref({
  state: 1, // 审核结果
  reason: '', // 原因说明
});
const typeOptions = ref([
  {
    label: '全部',
    value: '',
  },
  {
    label: '初始',
    value: 0,
  },
  {
    label: '待审核',
    value: 1,
  },
  {
    label: '已同意',
    value: 2,
  },
  {
    label: '已拒绝',
    value: -1,
  },
]);
const curetData = ref({});
const downLoading = ref(false);
const currentPage = ref(1); // 分页
const pageSize = ref(10);
const dateRange = ref([new Date().setMonth(new Date().getMonth() - 3), new Date().getTime()]);
const tableData = ref([]);
const count = ref(0);
const selection = ref({});
const dialogFormVisible = ref(false);
const currentOrder = ref('');
const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));

const getParams = () => {
  let params = {
    currentPage: currentPage.value,
    pageSize: pageSize.value,
  };
  if (dateRange.value && dateRange.value.length > 0) {
    set(params, 'createStartTime', dateRange.value[0]);
    set(params, 'createEndTime', dateRange.value[1]);
  }
  // 对账单名称
  if (searchData.value.orderNo) set(params, 'settlementInterval', searchData.value.orderNo);
  // 状态
  if (searchData.value.type || searchData.value.type === 0) set(params, 'auditStatus', searchData.value.type);
  // 供应商ID
  if (searchData.value.supplierID) set(params, 'supplierId', searchData.value.supplierID);
  // 供应商
  if (searchData.value.supplierName) set(params, 'supplierName', searchData.value.supplierName);
  return params;
};

const getSearchList = () => {
  let params = getParams();
  loading.value = true;
  app.$axios
    .post(app.$api.supplierSettlement.page, params)
    .then((res) => {
      tableData.value = res.data.records;
      count.value = Number(res.data.total);
      loading.value = false;
      selection.value = {};
      currentOrder.value = '';
    })
    .catch((err) => {
      console.log(err);
      loading.value = false;
    });
};

const onSearch = () => {
  // 修改搜索条件时把页码重置为1
  currentPage.value = 1;
  getSearchList();
};

const resetSearch = () => {
  dateRange.value = [new Date().setMonth(new Date().getMonth() - 3),
    new Date().getTime()];// 查询-时间范围
  // 初始化搜索条件
  searchData.value = {
    beginTime: '', // 查询-下单时间
    endTime: '', // 查询-下单时间
    orderNo: '', // 查询-订单编号
    type: '', // 查询-订单类型
    supplierID: '', // 查询-供应商
    supplierName: '', // 查询-供应商
  };
};

const formatTime = (time) => {
  return dayjs(Number(time)).format('YYYY-MM-DD HH:mm:ss');
};
// 进入详情
const handleDetail = (data, type) => {
  app.$router.push({
    path: '/supplyChain/statementAccount/detail',
    query: {
      auditStatusName: data.auditStatusName, // 对账单状态
      auditStatus: data.auditStatus, // 对账单状态
      settlementInterval: data.settlementInterval, // 对账单名称
      auditDescription: data.auditDescription, // 审核说明
      createTime: data.createTime, // 创建时间
      supplySettlementId: data.supplySettlementId, // 对账单id
      purchaseCount: data.purchaseCount, // 采购单数量
      purchaseAmount: data.purchaseAmount, // 采购单金额
      refundCount: data.refundCount, // 退货单数量
      refundAmount: data.refundAmount, // 退货单金额
      actuallyCount: data.actuallyCount, // 实际结算数量
      actuallyAmount: data.actuallyAmount, // 实际结算金额
      checkCount: data.checkCount, // 对账单数量
      checkAmount: data.checkAmount, // 对账单金额
      type,
    },
  });
};
// 提交
const submit = (row) => {
  app.$confirm('是否确认提交，确认后将提交给供应商审核。', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(() => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let params = {
      supplySettlementId: row.supplySettlementId,
      // settlementGoodsEditParamList: app.$refs.detailsInfos.changeData,
      operator: userInfo?.userRealName ? userInfo?.userRealName : userInfo?.userMobile,
      isCommit: 1,
    };
    app.$axios
      .post(app.$api.supplierSettlement.edit, params)
      .then(() => {
        app.$message.success('提交成功');
        getSearchList();
      })
      .catch((err) => {
        console.log(err);
      });
  }).catch(() => {

  });
};
// 审核
// const examine = (row) => {
//   curetData.value = row;
//   dialogFormVisible.value = true;
// };
// 审核取消
const examineClose = () => {
  dialogFormVisible.value = false;
  form.value = {
    state: '', // 审核结果
    reason: '', // 原因说明
  };
};
// 审核确认
const examineConfirm = () => {
  // 拒绝时必须填写原因
  if (form.value.state === -1 && !form.value.reason) {
    app.$message.error('请填写原因');
    return;
  }
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let params = {
    supplySettlementId: curetData.value.supplySettlementId,
    auditStatus: form.value.state,
    operator: userInfo?.userRealName ? userInfo?.userRealName : userInfo?.userMobile,
    auditDescription: form.value.reason,
  };
  app.$axios
    .post(app.$api.supplierSettlement.audit, params)
    .then(() => {
      app.$message.success('审核成功');
      getSearchList();
      dialogFormVisible.value = false;
      form.value = {
        state: '', // 审核结果
        reason: '', // 原因说明
      };
    })
    .catch((err) => {
      console.log(err);
    });
};

const handleSizeChange = (val) => {
  pageSize.value = val;
  currentPage.value = 1;
  getSearchList();
};

const handleCurrentChange = (val) => {
  currentPage.value = val;
  getSearchList();
};

const download = async () => {
  app.$message.success('正在导出数据，请稍后前往浏览器下载列表查看下载文件');
  let params = getParams();
  downLoading.value = true;
  const res = await app.$axios.post(app.$api.supplierSettlement.downloadCheckList, params, { responseType: 'blob' });
  downLoading.value = false;
  downloadFile(res, `订货对账单导出_${dayjs(new Date()).format('YYYY-MM-DD')}_${dayjs(new Date()).format('HH-mm-ss')}.xls`);
};

const exportData = async (row) => {
  app.$message.success('正在导出数据，请稍后前往浏览器下载列表查看下载文件');
  let params = {
    supplySettlementId: row.supplySettlementId,
  };
  downLoading.value = true;
  const res = await app.$axios.post(app.$api.supplierSettlement.downloadCheckGoods, params, { responseType: 'blob' });
  downLoading.value = false;
  // 获取文件名
  // const disposition = res.headers.get('content-disposition');
  // const filename = disposition.split('filename=')[1].replace(/"/g, '');
  downloadFile(res, `${row?.settlementInterval}_${dayjs(new Date()).format('YYYY-MM-DD')}_${dayjs(new Date()).format('HH-mm-ss')}.xlsx`);
  // downloadFile(res, '对账单明细数据.xls');
};

onMounted(() => {
  getSearchList();
});
</script>

<style lang="scss" scoped>
.tableBox{
  :deep(.el-table__header){
    .el-table__cell{
      font-weight: bold;
      color: #333;
    }
  }
  :deep(.el-table__row){
    font-size: 12px;
  }
}
</style>

<style scoped>
/* .tableBox >>> .el-table__expand-icon:after {
  content: '';
}
.tableBox >>> .el-table__expand-icon > i {
  display: none;
} */
/* .tableBox >>> .el-table__expand-column {
  border-right: none;
} */
.tableBox >>> .el-table__expanded-cell[class*=cell] {
  padding: 5px 50px !important;
}
</style>
